import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const TopicList = Loadable(lazy(() => import('app/views/topics/Topic')));
const TopicCreate = Loadable(lazy(() => import('app/views/topics/TopicCreate')));
const TopicUpdate = Loadable(lazy(() => import('app/views/topics/TopicUpdate')));

const topicRoutes = [
  {
    path: '/topics',
    element: <TopicList />,
    auth: authRoles.admin
  },
  {
    path: '/topics/create',
    element: <TopicCreate />,
    auth: authRoles.admin
  },
  {
    path: '/topics/edit/:topicId',
    element: <TopicUpdate />,
    auth: authRoles.admin
  }
];

export default topicRoutes;

