import { combineReducers } from 'redux';
import { AdminReducer } from './admin/admin.reducer';
import { CategoryReducer } from './category/category.reducer';
import { UserReducer } from './user/user.reducer';
import { LectureLogsReducer } from './lecture-logs/lectureLogs.reducer';
import { StudentLectureReducer } from './student-lecture/studentLecture.reducer';
import { TopicReducer } from './topic/topic.reducer';

// reducer import

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  admin: AdminReducer,
  category: CategoryReducer,
  user: UserReducer,
  lectureLogs: LectureLogsReducer,
  studentLecture: StudentLectureReducer,
  topic: TopicReducer
});

export default reducer;
