import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const View = Loadable(lazy(() => import('./View')));
const UserCreate = Loadable(lazy(() => import('app/views/users/UserCreate')));
const UserUpdate = Loadable(lazy(() => import('app/views/users/UserUpdate')));
const LectureView = Loadable(lazy(() => import('./LectureView')));
const QuestionView = Loadable(lazy(() => import('./QuestionView')));

const usersRoutes = [
  { path: '/teachers', element: <List type="teacher" />, auth: authRoles.admin },
  { path: '/students', element: <List type="student"/>, auth: authRoles.admin },
  {
      path: '/teachers/create',
      element: <UserCreate type="teacher" />,
      auth: authRoles.admin
  },
  {
      path: '/students/create',
      element: <UserCreate type="student" />,
      auth: authRoles.admin
  },
  {
    path: '/users/edit/:userId',
    element: <UserUpdate />,
    auth: authRoles.admin
  },
  {
    path: '/users/view/:userId',
    element: <View />,
    auth: authRoles.admin
  },
   {
     path: '/teacher-lecture/view/:lectureId',
     element: <LectureView />,
     auth: authRoles.admin
   },
   {
        path: '/student-lecture/view/:lectureId',
        element: <QuestionView type="students"/>,
        auth: authRoles.admin
   },
   {
        path: '/questions/view/:lectureId',
        element: <QuestionView type="teachers"/>,
        auth: authRoles.admin
   },
];

export default usersRoutes;
