import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const List = Loadable(lazy(() => import('app/views/lecture-logs/LectureLogs')));

const lectureLogRoutes = [
  {
    path: '/teacher-lecture-logs',
    element: <List type='teacher'/>,
    auth: authRoles.admin
  },
  {
    path: '/student-lecture-logs',
    element: <List type='student'/>,
    auth: authRoles.admin
  }
];

export default lectureLogRoutes;

