import TOPIC_ACTION_TYPES from './topic.types';

export const TOPIC_INITIAL_STATE = {
  topicLists: [],
};

export const TopicReducer = (state = TOPIC_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TOPIC_ACTION_TYPES.LOAD_TOPICS: {
      return { ...state, topicLists: payload };
    }
    default:
      return state;
  }
};
